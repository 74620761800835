import React, {useState, useEffect, useCallback} from 'react'
import Noty from 'noty';

import {getIndex} from '../../utils/helpers';
import requests from '../../utils/requests';

type ReviewFormProps = {
    OnLogout: any;
    reviewItem: any;
    saveStatus: Function;
    OnNext: Function;
}

const ReviewForm: React.FC<ReviewFormProps>  = ({OnLogout, reviewItem, saveStatus, OnNext}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [question, setQuestion] = useState('');
    const [unanswerable, setUnanswerable] = useState<boolean>(false);
    const [answer, setAnswer] = useState<string>('');
    const [questions, setQuestions] = useState<any[]>([]);
    const [editIndex, setEditIndex] = useState<number>(); 
    
    const resetForm = () => {
        setUnanswerable(false);
        setQuestion('');
        setAnswer('');
        setEditIndex(undefined);
    }

    useEffect(()=>{
        resetForm();
        if (reviewItem?.questions) {
            setQuestions(reviewItem.questions);
            setEditIndex(undefined);
        }
    }, [reviewItem])

    useEffect(()=>{
        if (error.length > 0) {
            new Noty({
                type: 'info',
                text: error,
                timeout: 3000,
                layout: 'topCenter',
            }).show();
        }
    }, [error])

    const captureText = useCallback(()=>{
        let txt = window.getSelection()?.toString() || '';
        if (txt!.length > 0) {
            if (getIndex(reviewItem.paragraph.text, txt) > -1) {
                setAnswer(txt);
            } else {
                setError(`'${txt}' not found in the text`);
            }
        }
    }, [reviewItem, setAnswer, setError])

    const addAnnotation = useCallback(()=>{
        if (question === '' || answer === '') {
            setError(`Please add a question first`)
            return;
        }
        setError('');

        const start = getIndex(reviewItem.paragraph.text, answer);
        if (start === -1) {
            setError(`'${answer}' not found in the text`)
            return;
        }
        const end = start+answer.length;
        setQuestions(old => [...old, {question, answer, start, end, unanswerable, action: 'create'}]);
        saveStatus(true);
        setQuestion('');
        setAnswer('')
        setUnanswerable(false);
    }, [setQuestions, question, answer, setQuestion, setAnswer, saveStatus, reviewItem, setError, unanswerable, setUnanswerable]);

    if (!reviewItem) return <div className="alert alert-info">Please select user(s)</div>

    const deleteQuestion = (idx: number) => {
        setQuestions((old)=>{
            let copy_old = [...old];
            if (copy_old[idx]?.action === "create") {
                copy_old = copy_old.filter((_item, index) => index !== idx)
            } else {
                copy_old[idx].action = "delete";
            }
            return copy_old;
        })
        saveStatus(true);
    }

    const saveQuestion = (idx: number) => {
        setQuestions((old)=>{
            let copy_old = [...old];
            copy_old[idx].question = question;
            copy_old[idx].answer = answer;
            copy_old[idx].unanswerable = unanswerable;
            if (copy_old[idx]?.action !== "create") {
                copy_old[idx].action = "update";
            }
            return copy_old;
        })
        resetForm();
        saveStatus(true);
    }

    const save = () => {
        setLoading(true);
        requests.saveReviewItem(reviewItem.paragraph.id.toString(), questions)
            .then(({data}) => {
                if (data.done) {
                    resetForm();
                    new Noty({
                        type: 'success',
                        text: 'Saved successfully',
                        timeout: 3000,
                        layout: 'topCenter',
                    }).show();
                    OnNext();
                } else {
                    throw new Error(data.data);
                }
            })
            .catch(e => {
                let error = e.message;
                if (e.response?.data) {
                    error = e.response.data.data;
                }
                setError(error);
            })
            .finally(()=>setLoading(false))
    }

    let sendBtnClass = "sendBtn ";
    if (answer.length > 0 && question.length > 0) {
        sendBtnClass += "ok"
    }

    let scdisable = false;
    if (loading || (Array.isArray(reviewItem.questions && reviewItem.questions.length < 1)) || editIndex !== undefined) {
        scdisable = true;
    }

    return (
        <div className="row no-gutters instructions">
            <div className="col-lg-10 offset-lg-1">
                <div className="row no-gutters">
                    <div className="col-lg-12">
                        <p className="indicator"><a href={reviewItem.paragraph.url} target="_blank" rel="noopener noreferrer">{reviewItem.paragraph.url}</a></p>
                        <h1 className="itemTitle">{reviewItem.paragraph.title}</h1>
                        <p onMouseUpCapture={()=>captureText()} className="itemText">{reviewItem.paragraph.text}</p>
                    </div>
                    {editIndex === undefined &&
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label className="label">Question</label>
                                <textarea className="form-control" placeholder="Type question" onChange={event=>setQuestion(event.target.value)} value={question} ></textarea>
                            </div>
                            <div className="form-group">
                                <label className="label" style={{cursor: 'pointer'}}><input type="checkbox" checked={unanswerable} onChange={(): void => setUnanswerable(old => !old)} />  Unanswerable Question</label>
                            </div>
                            <div className="form-group">
                                <label className="label">Answer</label>
                                <div className="row no-gutters">
                                    <div className="col-lg-11">
                                        <textarea className="form-control" placeholder="Highlight text to place an answer" value={answer} disabled={true} ></textarea>
                                    </div>
                                    <div className="col-lg-1 d-flex align-items-center justify-content-center">
                                        <span className={sendBtnClass} onClick={addAnnotation}><i className="fas fa-check"></i></span>
                                    </div>
                                </div>
                            </div>
                            <hr className="qhr" />
                        </div>
                    }
                    <div className="col-lg-12">
                        <div>
                            <p className="float-left qae">Questions and answers entered</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="row no-gutters">
                            <div className="col-lg-12">
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Question</th>
                                            <th>Answer</th>
                                            <th>Unanswerable</th>
                                            <th>Created by</th>
                                            <th>Actions</th>
                                            <th>Change</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {questions.map((q: any, idx: number)=>{
                                            if (editIndex === idx) {
                                                return (
                                                    <tr key={idx}>
                                                        <td><input type="text" className="form-control" value={question} onChange={(event)=>setQuestion(event.target.value)} /></td>
                                                        <td>{answer}</td>
                                                        <td><label><input type="checkbox" checked={unanswerable} onChange={()=>setUnanswerable(old => !old)} /> Unanswerable</label></td>
                                                        <td>{q.user ? q.user : 'You'}</td>
                                                        <td>
                                                            <div className="btn-group">
                                                                <button className="btn btn-sm btn-info" onClick={()=>saveQuestion(idx)}>Save</button>
                                                                <button className="btn btn-sm btn-dark" onClick={()=>resetForm()}>Cancel</button>
                                                                <button className="btn btn-sm btn-danger" onClick={()=>deleteQuestion(idx)}>Delete</button>
                                                            </div>
                                                        </td>
                                                        <td>{q?.action ? q.action : null}</td>
                                                    </tr>
                                                )
                                            }
                                            return (
                                                <tr key={idx}>
                                                    <td>{q.question}</td>
                                                    <td>{q.answer}</td>
                                                    <td>{q.unanswerable ? 'Yes' : 'No'}</td>
                                                    <td>{q.user ? q.user : 'You'}</td>
                                                    <td>
                                                        {!loading && 
                                                            <div className="btn-group">
                                                                <button className="btn btn-sm btn-warning" onClick={()=>{
                                                                    setUnanswerable(q.unanswerable);
                                                                    setQuestion(q.question);
                                                                    setAnswer(q.answer);
                                                                    setEditIndex(idx)
                                                                }}>Edit</button>
                                                                <button className="btn btn-sm btn-danger ml-1" onClick={()=> deleteQuestion(idx)}>Delete</button>
                                                            </div>
                                                        }
                                                    </td>
                                                    <td>{q?.action ? q.action : null}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row no-gutters">
                    <div className="col-md-12 scbtnarea">
                        {loading && <p className="text-center pb-2"><i className="fa fa-spin fa-spinner fa-2x"></i></p>}
                        <button className="scbtn" disabled={scdisable} onClick={event=>save()}>Save and Continue</button>
                    </div>
                </div>
            </div>
        </div>      
    )
}

export default ReviewForm;