export const get_session = (): {auth: any, config: any} | null => {
    let user_data = localStorage.getItem('user_data');
    if (!user_data) return null;
    user_data = JSON.parse(user_data);

    let user_config: any = localStorage.getItem('user_config');
    if (!user_config) {
        user_config = {};
    } else {
        user_config = JSON.parse(user_config);
    }

    return {
        auth: user_data,
        config: user_config
    };
}

export const set_config = (new_config: any) => {
    let user_config: any = localStorage.getItem('user_config');
    if (!user_config) {
        user_config = {}
        if (localStorage.getItem('shuffle')) {
            user_config.shuffle = localStorage.getItem('shuffle');
            localStorage.removeItem('shuffle');
        }
        if (localStorage.getItem('document_id')) {
            user_config.document_id = localStorage.getItem('document_id');
            localStorage.removeItem('document_id');
        }    
    } else {
        user_config = JSON.parse(user_config);
    }
    user_config = {...user_config, ...new_config};
    localStorage.setItem('user_config', JSON.stringify(user_config))
}

export const getIndex = (item: string, answer: string) => {
    const itemText = item.replace(/\s*\n/g, ' ');
    const selectedText = answer.replace(/\s*\n/g, ' ');
    const start = itemText.indexOf(selectedText); 
    return start;
}