import {set_config} from '../utils/helpers';

export const SET_SHUFFLE = 'SET_SHUFFLE';

export const setShuffle = (on: boolean = true): any => {
    set_config({shuffle: on ? '1' : '0'})
    return {
        type: SET_SHUFFLE,
        on,
    }
}