import React, {useState, useEffect, useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Noty from 'noty';

import {setShuffle} from '../../reducers/configActions';
import {getIndex, set_config} from '../../utils/helpers'

import requests from '../../utils/requests'
import './styles.css'

type Item = {
    total_created: number;
    today_questions?: number;
    id: number;
    document_id: number;
    url: string;
    title: string;
    paragraph: {
        from: number,
        total: number
    };
    document: {
        from: number,
        total: number
    };
    text: string;
    questions: any[];
    delete?: number[];
} | null;

type AnnotateFormProps = {
    OnLogout: any;
}

const AnnotateForm: React.FC<AnnotateFormProps>  = ({OnLogout}) => {
    const shuffle = useSelector<any,any>(state=> state.config.shuffle);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [item, setItem] = useState<Item>(null);
    const [question, setQuestion] = useState('');
    const [unanswerable, setUnanswerable] = useState<boolean>(false);
    const [answer, setAnswer] = useState<string>('');
    const [seconds, setSeconds] = useState<number>(0);
    const [timer, setTimer] = useState<any>();
    const [changed, setChanged] = useState<boolean>(false);
    const dispatch = useDispatch()

    const shuffleHandler = useCallback((value: boolean)=>{
        dispatch(setShuffle(value));
    }, [dispatch])

    const confirmExit = () => {
        return 'Unsaved changes will be lost. Are you sure you want to quit?';
    }

    useEffect(()=>{
        if (changed) {
            window.onbeforeunload = confirmExit;
        } else {
            window.onbeforeunload = null
        }
    }, [changed])

    useEffect(()=>{
        if (error.length > 0) {
            new Noty({
                type: 'info',
                text: error,
                timeout: 3000,
                layout: 'topCenter',
            }).show();
        }
    }, [error])
       
    const setItemNow = useCallback((data)=>{
        clearInterval(timer);
        setSeconds(0);
        setTimer(setInterval(()=>{
            setSeconds(s=>s+1)
        }, 1000));
        set_config({"document_id": data.document_id.toString()})
        localStorage.removeItem("document_id")
        
        if (localStorage.getItem('item')) {
            const saved_item = JSON.parse(localStorage.getItem('item')!);
            if (saved_item.document_id === data.document_id && saved_item.id === data.id) {
                setItem({...data, questions: [...saved_item.questions]})
                setChanged(true);
            } else {
                setItem(data);
            }
        } else {
            setItem(data);
        }
    }, [timer, setItem])

    const getItem = useCallback(async()=>{
        try {
            setError('');
            setLoading(true);
            const {data} = await requests.getItem(shuffle);
            if (!data.done) throw new Error(data.data);
            setItemNow(data.data)
        } catch (e) {
            if (e.response) {
                if (e.response.data.relogin) {
                    OnLogout();
                } else if (e.response.data.data) {
                    setError(e.response.data.data)
                } else {
                    setError(e.message)
                }
            } else {
                setError(e.message)
            }
        } finally {
            setLoading(false)
        }
    }, [setItemNow, OnLogout, shuffle])


    useEffect(()=>{
        getItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addAnnotation = useCallback(()=>{
        if (question === '' || answer === '' || !item) {
            setError(`Please add a question first`)
            return;
        }
        setError('');

        const start = getIndex(item.text, answer);
        if (start === -1) {
            setError(`'${answer}' not found in the text`)
            return;
        }
        const end = start+answer.length;

        setItem((i: any)=>{
            const new_item = {...i, questions: [...i.questions, {question, answer, start, end, unanswerable}]};
            localStorage.setItem('item', JSON.stringify(new_item))
            return new_item;
        })
        setChanged(true);
        setQuestion('');
        setAnswer('')
        setUnanswerable(false);
    }, [question, answer, setItem, setQuestion, setAnswer, setChanged, item, setError, unanswerable, setUnanswerable]);

    const deleteQuestion = useCallback((idx: number)=>{
        // eslint-disable-next-line no-restricted-globals
        const c = confirm("Are you sure?");
        if (!c) return;
        setItem((olditems: any)=>{
            const delItem = [...olditems.questions].find((q, index)=>index===idx);
            const questions = [...olditems.questions].filter((q, index)=>index!==idx);
            let newItems = {...olditems, questions}
            if (delItem && delItem.question_id) {
                if (olditems.delete) {
                    newItems.delete = [...olditems.delete, delItem.question_id]
                } else {
                    newItems.delete = [delItem.question_id]
                }
            }
            localStorage.setItem('item', JSON.stringify(newItems))
            return newItems
        });
        if (item!.questions?.length < 1) {
            setChanged(false)
        } else {
            setChanged(true);
        }
    }, [setItem, item, setChanged])

    const page = useCallback(async (ignore: boolean = false)=>{
        if (loading) return;
        
        setError('');
        
        if(ignore===false && changed) {
            try {
                setLoading(true)
                const {data} = await requests.saveItem(item);
                if (!data.done) throw new Error(data.data);
                localStorage.removeItem('item');
                setChanged(false)
                setLoading(false)
                setError(data.data)
                setAnswer('');
                setItem(null);
            } catch (e) {
                if (e.response.data.next) {
                    setItem(null);
                    setChanged(false)
                    setError(e.response.data.data);
                    getItem();
                } else if (e.response.data.relogin) {
                    OnLogout();
                } else if (e.response.data.data) {
                    setError(e.response.data.data)
                } else {
                    setError(e.message)
                }
                setLoading(false)
                return;
            }
        }

        getItem();
        
    }, [getItem, changed, setLoading, item, setChanged, OnLogout, setError, loading])

    const dismiss = useCallback(async()=>{
        if (!item) return;
        if (item.questions.length > 0) {
            setError('Please remove questions')
            return
        }
        // eslint-disable-next-line no-restricted-globals
        const c = confirm("Dimiss this paragraph because it doesn't have relevant content.");
        if (!c) return;
        
        try {
            setLoading(true);
            const {data} = await requests.dismiss(item?.id);
            if (!data.done) throw new Error(data.data);
            setItem(null);
            setLoading(false) 
            setChanged(false)
            setError(data.data)
            page(true)
        } catch (e) {
            if (e.response) {
                if (e.response.data.next) {
                    setItem(null);
                    page(true)
                    setError(e.response.data.data);
                    getItem();
                    return;
                } else if (e.response.data.relogin) {
                    OnLogout();
                } else {
                    setError(e.response.data.data)
                }
            } else {
                setError(e.message)
            }
            setLoading(false) 
        }
    }, [item, OnLogout, page, setChanged, setItem, getItem])

    const captureText = useCallback(()=>{
        let txt = window.getSelection()?.toString() || '';
        if (txt.indexOf("Dismiss Text") > -1) {
            txt = txt!.substring(0, txt.indexOf("Dismiss Text")).trim()
        }
        if (item && txt!.length > 0) {
            if (getIndex(item.text, txt) > -1) {
                setAnswer(txt);
            } else {
                setError(`'${txt}' not found in the text`);
            }
        }
    }, [item, setAnswer, setError])

    const wordCount = useCallback(() => {
        if (!item) return 0;
        const text = item.text;
        const words = text.split(' ').filter(w=>w.length > 2);
        return Math.floor(words.length / 10);
    }, [item]);

    const toHHMMSS = (secs: any) => {
        var sec_num = parseInt(secs, 10)
        var hours   = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60
    
        return [hours,minutes,seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v,i) => v !== "00" || i > 0)
            .join(":")
    }

    let sendBtnClass = "sendBtn ";
    if (answer.length > 0 && question.length > 0) {
        sendBtnClass += "ok"
    }

    let qacClass = "float-right qac ";
    if (item && item.questions.length >= wordCount()) {
        qacClass += "ok"
    }

    let scdisable = false;
    if (loading || (item && Array.isArray(item.questions) && item.questions.length < 1)) {
        scdisable = true;
    }

    const getChecekd = () => {
        if (shuffle === "0" || shuffle === false) return false;
        return true;
    }

    return (
        <>
            <div className="row no-gutters instructions">
                <div className="col-lg-10 offset-lg-1">
                <p>
                    <label className="switch mr-2">
                    <input type="checkbox" onChange={(event) => shuffleHandler(event.target.checked)} checked={getChecekd()}/>
                    <span className="slider round"></span>
                    </label>
                    Shuffle sections
                </p>
                <div className="totalQuestions">Questions created: Today: {item?.today_questions} Total: {item?.total_created}</div>
                <h6 className="title">Instructions:</h6>
                <p>
                    Please read the text below, formulate a question based on the text below and answer it by highlighting the part of the text that contains the answer and click the checkmark. We suggest entering at least 5 questions per text. Dismiss Text if the text does not have annotative material.
                </p>
                <h6 className="sub-title" id="unanswerable-questions">Unanswerable questions</h6>
                <p className="unansquesp">
                    1. The unanswerable questions should appear relevant to the topic of the context paragraph (e.g. use the same keywords)<br/>
                    <br />
                    2. There should be some span in the context whose type matches the type of answer the question asks for. For example, if the question asks, “What company was founded in 1992?”, then some company should appear in the context. However, the whole point is you cannot find the answer to the question because the paragraph doesn’t provide enough information.
                </p>
                <hr />
                </div>
            </div>
            <div className="row no-gutters">
                <div className="col-lg-10 offset-lg-1">
                    {item &&
                        <div className="row no-gutters">
                            <div className="col-lg-12">
                                <p className="float-right seconds">{toHHMMSS(seconds)}</p>
                                <p className="indicator"><a href={item.url} target="_blank" rel="noopener noreferrer">{item.url}</a></p>
                                <h1 className="itemTitle">{item.title}</h1>
                                <p className="indicator">Paragraph {item.paragraph.from} of {item.paragraph.total} | Document {item.document.from} of {item.document.total}</p> 
                                <p onMouseUpCapture={()=>captureText()} className="itemText">{item.text}</p>
                                <button className="dismissText btn btn-danger btn-sm" disabled={!item || item.questions.length > 0} onClick={dismiss}>Dismiss Text</button>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="label">Question</label>
                                    <textarea className="form-control" placeholder="Type question" onChange={event=>setQuestion(event.target.value)} value={question} ></textarea>
                                </div>
                                <div className="form-group">
                                    <label className="label" style={{cursor: 'pointer'}}><input type="checkbox" checked={unanswerable} onChange={(): void => setUnanswerable(old => !old)} />  Unanswerable Question</label>
                                    <a href="#unanswerable-questions" className="ml-2"><i className="fa fa-info-circle"></i></a>
                                </div>
                                <div className="form-group">
                                    <label className="label">Answer</label>
                                    <div className="row no-gutters">
                                        <div className="col-lg-11">
                                            <textarea className="form-control" placeholder="Highlight text to place an answer" value={answer} disabled={true} ></textarea>
                                        </div>
                                        <div className="col-lg-1 d-flex align-items-center justify-content-center">
                                            <span className={sendBtnClass} onClick={addAnnotation}><i className="fas fa-check"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <hr className="qhr" />
                            </div>
                            <div className="col-lg-12">
                                <div>
                                    <p className="float-left qae">Questions and answers entered</p>
                                    <p className={qacClass}>{item.questions.length}/{wordCount()} annotations entered</p>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="row no-gutters">
                                    <div className="col-lg-6">
                                        <h6 className="tqtitle">Question</h6>
                                        {item.questions.map((q, idx)=>{
                                            return (
                                                <div className="tq-box1" key={idx}>
                                                    {q.question}
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="col-lg-6">
                                        <h6 className="tqtitle">Answer</h6>
                                        {item.questions.map((q, idx)=>{
                                            return (
                                                <div key={idx} className="tq-box">
                                                    <div className="tq-box2">
                                                        {q.unanswerable ? <span className="badge badge-info d-block">Unanswerable Question</span> : null}
                                                        {q.answer}
                                                    </div>
                                                    <i className="fa fa-minus delbtn" aria-hidden="true" onClick={()=>deleteQuestion(idx)}></i>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                    <div className="row no-gutters">
                        <div className="col-md-12 scbtnarea">
                            {loading && <p className="text-center pb-2"><i className="fa fa-spin fa-spinner fa-2x"></i></p>}
                            <button className="scbtn" onClick={()=>page()} disabled={scdisable}>Save and Continue</button>
                        </div>
                    </div>
                </div>
            </div>    
        </>  
    )
}

export default AnnotateForm;