/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'

import Auth from './components/Auth';
import AnnotateForm from './components/AnnotateForm';
import ReviewForm from './components/ReviewForm';
import SelectUsers from './components/SelectUsers';
import requests from './utils/requests';
import {Modes} from './utils/constants';
import {logout} from './reducers/authActions'

import Logo from './images/Logo.png';
import './App.css';

function App() {
  const username = useSelector<any, any>(state=> state.auth.username);
  const is_admin = useSelector<any, any>(state=> state.auth.is_admin);
  const [mode, setMode] = useState<Modes>(Modes.Annotate);
  const [reviewItem, setReviewItem] = useState<any>();
  const [saved, setSaved] = useState<boolean>(false);
  const [nextKey, setNextKey] = useState<number>(0);
  const dispatch = useDispatch()

  const logoutHandler = useCallback(()=>{
    requests.logout();
    dispatch(logout())
  }, [dispatch]);

  const getNextReview = () => {
    setReviewItem(null);
    setSaved(false);
    setNextKey(old => old + 1);
  }

  if (!username) {
    return <Auth />
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-class">
        <a className="navbar-brand d-flex flex-column align-items-center justify-content-center" href="/">
            <img src={Logo} alt="logo" />
            <span className="logoText">Annotation</span>
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            {is_admin &&
              <>
                {saved && <span className="badge badge-warning">Save Required <button onClick={(event) => getNextReview()} className="btn btn-sm btn-warning">Cancel</button></span>}
                {!saved &&
                  <li className="nav-item d-flex align-items-center">
                    <a className="nav-link" href="#" onClick={event => {
                      event.preventDefault();
                      if (mode === Modes.Annotate) {
                        setMode(Modes.Review);
                      } else {
                        setMode(Modes.Annotate);
                      }
                    }}>{mode === Modes.Annotate ? 'Review' : 'Annotate'} mode</a>
                  </li>
                }
              </>
            }
            {is_admin && 
              <SelectUsers
                OnSetReviewItem={setReviewItem}
                disable={saved} 
                mode={mode}
                refkey={nextKey}
              />
            }
            <li className="nav-item d-flex align-items-center">
              <a className="nav-link disabled username" href="#">{username}</a>
            </li>
            <li className="nav-item d-flex align-items-center">
              <a className="nav-link logoutbtn" href="#" onClick={(event)=>logoutHandler()}>Logout</a>
            </li>
          </ul>
        </div>
      </nav>
      {mode === Modes.Annotate && <AnnotateForm OnLogout={logoutHandler} />}
      {mode === Modes.Review && 
        <ReviewForm 
          OnLogout={logoutHandler}
          reviewItem={reviewItem}
          saveStatus={(status: boolean)=>setSaved(status)}
          OnNext={getNextReview}
        />}
    </>
  );
}

export default App;
