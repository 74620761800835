import axios from 'axios';
import {ENDPOINT_URL} from './constants';
import {get_session} from './helpers';

export default {
    login: (email: string, password: string, grToken: string, remember: boolean = false) => {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        if (remember) {
            formData.append('remember', '1');
        }
        formData.append('grToken', grToken);
        return axios.post(`${ENDPOINT_URL}/user/login/`, formData);  
    },
    logout: () => {
        return axios.patch(`${ENDPOINT_URL}/user/login/?token=${get_session()?.auth?.token}`);  
    },
    getItem: (shuffle_value: any) => {
        let document_id = '';
        if (get_session()?.config?.document_id) {
            document_id = `&document_id=${get_session()?.config?.document_id}`
        }
        let shuffle = "0";
        if (shuffle_value === true || shuffle_value === 1 || shuffle_value === "1") {
            shuffle = "1";
        }
        return axios.get(`${ENDPOINT_URL}/api/item/?shuffle=${shuffle}${document_id}`, { headers: { Authorization: get_session()?.auth?.token } });
    },
    getClassifications: () => {
        return axios.get(`${ENDPOINT_URL}/api/classifications/`, { headers: { Authorization: get_session()?.auth?.token } });
    },
    saveItem: (item: any) => {
        const formData = new FormData();
        formData.append('token', get_session()?.auth?.token);
        formData.append('id', item.id)
        formData.append('questions', JSON.stringify(item.questions))
        if (item.delete && item.delete.length > 0) {
            formData.append('delete', JSON.stringify(item.delete))
        }
        return axios.post(`${ENDPOINT_URL}/api/item/`, formData)
    },
    dismiss: (item_id: number) => {
        const formData = new FormData();
        formData.append('token', get_session()?.auth?.token);
        formData.append('id', item_id.toString());
        return axios.patch(`${ENDPOINT_URL}/api/item/`, formData)
    },
    users: () => {
        return axios.get(`${ENDPOINT_URL}/api/users/?token=${get_session()?.auth?.token}`)
    },
    getReviewItem: (users: number[], source: string) => {
        let users_json = '';
        if (users && JSON.stringify(users) !== JSON.stringify([0])) {
            users_json = `&users=${JSON.stringify(users)}`;
        } 
        let source_string = '';
        if (source) {
            source_string = `&source=${source}`;
        }
        return axios.get(`${ENDPOINT_URL}/api/review_item/?token=${get_session()?.auth?.token}${users_json}${source_string}`)
    },
    saveReviewItem: (paragraph_id: string, questions: any[]) => {
        const formData = new FormData();
        formData.append('token', get_session()?.auth?.token);
        formData.append('id', paragraph_id)
        formData.append('questions', JSON.stringify(questions))
        return axios.post(`${ENDPOINT_URL}/api/review_item/`, formData)
    }
}