let endurl = '';
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    endurl = 'http://127.0.0.1:8000'
}

export const ENDPOINT_URL = endurl;
export const SITE_KEY = '6LcgiM8UAAAAAAr1SSf2gULniWqbkDiU48hhVrtg';

export enum Modes {
    Annotate,
    Review
}