import React, {useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import { load } from 'recaptcha-v3';

import Logo from '../../images/Logo.png';
import {setAuth} from '../../reducers/authActions';
import requests from '../../utils/requests'
import {SITE_KEY} from '../../utils/constants';
import './styles.css';

const Auth = () =>{
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [reme, setReme] = useState<boolean>(false);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const dispatch = useDispatch()
    
    const submitHandler = useCallback(async(event)=>{
        event.preventDefault();

        setError('')
        setLoading(true)

        try {
            const recaptcha = await load(SITE_KEY, {autoHideBadge: true})
            const token = await recaptcha.execute('login') 
            const {data} = await requests.login(email, password, token, reme);
            if (!data.done) {
                setError(data.data)
                setLoading(false)
            } else {
                dispatch(setAuth(data.email, data.data, data.userID, data.is_admin));
            }
        } catch (e) {
            if (e.response && e.response.data) {
                setError(e.response.data.data);
            } else {
                setError(e.message);
            }
            setLoading(false)
        }

    }, [email, password, reme, dispatch])

    return (
        <>
            <div className="row no-gutters header">
                <div className="mx-auto d-flex flex-column align-items-start justify-content-center">
                    <img src={Logo} alt="logo" />
                    <span className="logoText">Annotation</span>
                </div>
            </div>
            <div className="row no-gutters auth-form-box">
                <div className="col-lg-4 offset-lg-4 auth-box">
                    <h1 className="auth-title">Log In</h1>
                    <hr className="auth-hr" />
                    <form method="POST" onSubmit={submitHandler} className="auth-form">
                        <div className="form-group">
                            <input type="email" id="email" placeholder="Email" className="auth-input" required value={email} onChange={event=>setEmail(event.target.value)} disabled={loading} />
                        </div>
                        <div className="form-group">
                            <input type="password" id="password" placeholder="Enter Password" className="auth-input" required value={password} onChange={event=>setPassword(event.target.value)} disabled={loading} />
                        </div>
                        <div className="form-group auth-rm">
                            <label><input type="checkbox" onChange={event=>setReme(event.target.checked)} checked={reme} /> Remember me</label>
                        </div>
                        <div className="form-group text-center">
                            {error && <div className="text-info p-3">{error}</div>}
                            {loading && <i className="fa fa-spin fa-spinner fa-2x"></i>}
                            {!loading && <button className="auth-login-btn">Log In</button>}
                        </div>
                        <div className="form-group auth-rm">
                            <p>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Auth;