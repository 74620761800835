export const SET_AUTH = 'SET_AUTH';

export const setAuth = (username: string, token: string, user_id: any, is_admin: boolean): any => {
    const user_data = {
        user_id,
        username,
        token,
        is_admin
    }
    localStorage.setItem("user_data", JSON.stringify(user_data));
    localStorage.removeItem("userId");

    localStorage.removeItem("username");
    localStorage.removeItem("token");
    localStorage.removeItem("expire");

    return {
        type: SET_AUTH,
        username,
        token,
        user_id,
        is_admin
    }
}

export const logout = (): any => {
    localStorage.removeItem("user_data");
    return {
        type: SET_AUTH,
        username: null,
        token: null,
        user_id: null,
        is_admin: false
    }
}