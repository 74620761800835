/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {SET_SHUFFLE} from './configActions';
import {get_session} from '../utils/helpers'

type Action = {
    type: string;
    on: boolean;
}

type State = {
    [index: string]: string | null | boolean; 
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function auth(state: State = {
    shuffle: false
}, action: Action): any {
    const shuffle = get_session()?.config?.shuffle
    switch (action.type) {
        case SET_SHUFFLE:
            const newState = {
                shuffle: action.on
            };
            return newState;
        default:
            return {...state, shuffle};
    }
}
  