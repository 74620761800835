import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import Noty from 'noty';

import requests from '../../utils/requests';
import { Modes } from '../../utils/constants';
import './styles.css';

type SelectUsersProps = {
    OnSetReviewItem: Function;
    disable: boolean;
    mode: Modes,
    refkey: number,
}

let defaultValue: any[] = [];
let sources: string[] = [];
let selectedSource: string;

const SelectUsers: React.FC<SelectUsersProps>  = ({OnSetReviewItem, disable, mode, refkey}) => {
    const [users, setUsers] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(()=>{    
        setLoading(true);
        requests.users()
            .then(({data})=>{
                if (data.done) {
                    sources = data.sources;
                    const users_ar = data.users.map((user: any)=>{
                        return {
                            value: user[0],
                            label: user[1],
                        }
                    })
                    setUsers([{value: 0, label: 'None'}, ...users_ar])
                } else {
                    throw new Error(data);
                }
            })
            .catch(e=>{
                let error = e.message;
                if (e.response?.data) {
                    error = e.response.data.data;
                }
                new Noty({
                    type: 'warning',
                    text: error,
                    timeout: 3000,
                    layout: 'topCenter',
                }).show();
            })
            .finally(()=>setLoading(false));
    }, []);

    const getNext = (user_ids: number[]) =>{
        setLoading(true);
        requests.getReviewItem(user_ids, selectedSource)
            .then(({data})=>{
                if (data.done) {
                    OnSetReviewItem(data.data)
                } else {
                    throw new Error(data.data);
                }
            })
            .catch(e=>{
                OnSetReviewItem(null);
                let error = e.message;
                if (e.response?.data) {
                    error = e.response.data.data;
                }
                new Noty({
                    type: 'warning',
                    text: error,
                    timeout: 3000,
                    layout: 'topCenter',
                }).show();
            })
            .finally(()=>setLoading(false))
    }

    useEffect(()=>{
        if (refkey > 0 && defaultValue.length > 0) {
            const user_ids = defaultValue.map(v => {
                return parseInt(v.value);
            })
            console.log(user_ids)
            getNext(user_ids);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refkey])

    if (mode !== Modes.Review) return null;

    return (
        <>
            <select 
                className="source-select"
                disabled={loading || disable} 
                onChange={event => {
                    selectedSource = event.target.value;
                    if (defaultValue.length > 0) {
                        let user_ids = defaultValue.map((selectedUser: any) => {
                            return parseInt(selectedUser.value)
                        })
                        getNext(user_ids);
                    }
                }}
                defaultValue={sources.indexOf(selectedSource) > -1 ? selectedSource : undefined}>
                    <option value="">Select a source</option>
                    {sources.map((src, idx)=>{
                        return <option key={idx}>{src}</option>
                    })}
            </select>
            <Select 
                options={users}
                isDisabled={loading || disable}
                isLoading={loading}
                isSearchable
                isMulti
                className="users-select"
                placeholder="Select User(s)"
                defaultValue={defaultValue}
                onChange={(selected_users)=>{
                    defaultValue = selected_users;
                    if (!selected_users) {
                        OnSetReviewItem(null);
                        return;
                    }
                    let user_ids = selected_users.map((selectedUser: any) => {
                        return parseInt(selectedUser.value)
                    })
                    getNext(user_ids);
                }}
            />
        </>
    )
}

export default SelectUsers;