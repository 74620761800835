/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {get_session} from '../utils/helpers';
import {SET_AUTH} from './authActions';

type Action = {
    type: string;
    username: string | null;
    token: string | null;
    user_id: string | null;
    is_admin: boolean;
}

type State = {
    [index: string]: string | boolean | null; 
}

const initial_state = {
    type: 'default',
    username: get_session()?.auth?.username,
    token: get_session()?.auth?.token,
    user_id: get_session()?.auth?.user_id,
    is_admin: get_session()?.auth?.is_admin
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function auth(state: State = initial_state, action: Action): State {
    switch (action.type) {
        case SET_AUTH:
            const newState = {
                username: action.username,
                token: action.token,
                user_id: action.user_id,
                is_admin: action.is_admin,
            };
            return newState;
        default:
            return state;
    }
}
  